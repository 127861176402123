<template>
    <div ref="hoverElement" style="background-color: cornsilk!important;max-width: 600px;text-wrap: pretty;" 
    class="popover-personinfo bs-popover-auto fade shadow" role="tooltip">
        <div v-if="isLoaded" class="popover-header p-2" style="background-color: cornsilk!important;">
            <div class="fw-bold d-flex ">
                <small>{{activityInfo.Name}} (ID: {{activityInfo.ID}})</small>
                <div class="me-2 ms-auto" style="cursor: default;">
                    <a :href="`/activity?ID=${activityInfo.ID}`"><i class="bi bi-box-arrow-up-right"></i></a>
                </div>
            </div>
        </div>
        <h3 v-else class="popover-header p-2">
            {{$t('Loading...')}}
        </h3>

        <div class="popover-body p-2">
            <template v-if="isLoaded">
            
                <div class="row gx-5" style="font-size:small">
                    <div class="col-12 mb-0">
                        <span style="font-weight: 500;">{{ $t("OrgUnit") }}:</span>&nbsp;<span>{{ activityInfo.OrgUnit }}</span>
                    </div>
                    <div class="col-12 mb-0">
                        <span style="font-weight: 500;">{{ $t("Activity Type") }}:</span>&nbsp;<span>{{ activityInfo.ActivityType }}</span>
                    </div>
                    <div class="col-12 mb-0" v-if="activityInfo.ActivityGroup">
                        <span style="font-weight: 500;">{{ $t("Activity Group") }}:</span>&nbsp;<span>{{ activityInfo.ActivityGroup }}</span>
                    </div>
                    <div class="col-12 mb-0" v-if="activityInfo.CompletionPhase">
                        <span style="font-weight: 500;">{{ $t("Completion Phase") }}:</span>&nbsp;<span>{{ activityInfo.CompletionPhase }}</span>
                    </div>
                    <div class="col-12 mb-0" v-if="activityInfo.HandoverType">
                        <span style="font-weight: 500;">{{ $t("Handover Type") }}:</span>&nbsp;<span>{{ activityInfo.HandoverType }}</span>
                    </div>
                    <div class="col-12 mb-2"></div>
                    <div class="col-12 mb-0" v-if="activityInfo.CheckItemsStatus && activityInfo.CheckItemsStatus !== 'N/A'">
                        <span style="font-weight: 500;">{{ $t("Check Items") }}:</span>&nbsp;<span>{{ activityInfo.CheckItemsStatus }}</span>
                    </div>
                    <div class="col-12 mb-0" v-if="activityInfo.TotalSignatures>0 && activityInfo.Completed && !activityInfo.Closed">
                        <span style="font-weight: 500;">{{ $t("Signatures") }}:</span>&nbsp;<span>{{ activityInfo.SignatureStatus }}</span>
                    </div>
                    
                    <div class="col-12 mb-0 mt-2" v-if="activityInfo.IShallSign">
                        <span style="font-weight: 500;font-size:larger">{{ $t("Awaiting my signature") }}</span>
                    </div>
                    <div class="mt-1" style="max-height: 100px; overflow-y: hidden;">
                        {{ activityInfo.Description }}
                    </div>
                    <div class="col-12 mb-0 mt-1 fst-italic" style="font-size:smaller">
                        {{ $t("Created by:") }} {{ activityInfo.CreatedBy }} {{ $formatDate(activityInfo.Created, 'Short Date') }}
                    </div>
                </div>

            </template>
            <template v-else>
                {{$t('Loading...')}}
            </template>
        </div>
    </div>
</template>

<script lang="ts">

const ActivityCache = new Map<number, IactivityInfo>();

export interface IactivityInfo {
    ID: string;
    Name: string;
    OrgUnit: string;
    ActivityType: string;
    ActivityGroup: string;
    CompletionPhase: string;
    HandoverType: string;
    Created: Date;
    Completed: Date;
    Closed: Date;
    CreatedBy: string;
    Description: string;
    CheckItemsStatus: string;
    SignatureStatus: string;
    TotalSignatures: Number;
    OutstandingSignatures: Number;
    IShallSign: Boolean;
};

export interface IActivityHoverProps {
    activityId?: number;
}
</script>

<script setup lang="ts">
import { API } from 'o365-modules';
import { ref, watch, onMounted } from 'vue';

const props = defineProps<IActivityHoverProps>();

const showContent = ref(false);
const isLoaded = ref(false);
const activityInfo = ref<IactivityInfo | null>(null);
const hoverElement = ref<HTMLElement | null>(null);

function hoverShow() {
    showContent.value = true;
    if (isLoaded.value && activityInfo.value?.ID != `${props.activityId}`) {
        handleShow();
    }
}

function hoverHide() {
    showContent.value = false;
}

defineExpose({hoverElement, hoverHide, hoverShow});

async function loadActivityData(activityId: number) {
    const result = await API.request({
        requestInfo: '/nt/api/data/aviw_Complete_Activities',
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            "viewName": "aviw_Complete_Activities",
            "distinctRows": false,
            "skip": 0,
            "fields": [
                { name: "ID", type: "string" },
                { name: "Name", type: "string" },
                { name: "OrgUnit", type: "string" },
                { name: "CompletionPhase", type: "string" },
                { name: "HandoverType", type: "string" },
                { name: "ActivityType", type: "string" },
                { name: "ActivityGroup", type: "string" },
                { name: "Description", type: "string" },
                { name: "CreatedBy", type: "string" },
                { name: "CheckItemsStatus", type: "string" },
                { name: "SignatureStatus", type: "string" },
                { name: "TotalSignatures", type: "number" },
                { name: "OutstandingSignatures", type: "number" },
                { name: "IShallSign", type: "number" },
                { name: "Created", type: "date" },
                { name: "Deleted", type: "date" },
                { name: "Completed", type: "date" },
                { name: "Closed", type: "date" }
            ],
            "maxRecords": 1,
            "whereClause": `ID = ${activityId}`,
            "masterDetailString": null,
            "filterString": null,
            "operation": "retrieve"
        })
    });
    return result != null ? result[0] : result;
}

async function handleShow() {
    isLoaded.value = false;
    activityInfo.value = null;
    if (props.activityId == null) { return; }
    let activityData = ActivityCache.get(props.activityId);
    if (activityData == null) {
        try {
            activityData = await loadActivityData(props.activityId);
            if (activityData == null) { return; }
            ActivityCache.set(props.activityId, activityData);
        } catch (ex) {
            console.error(ex);
            return;
        }
    }
    activityInfo.value = activityData;
    isLoaded.value = true;
}

watch(() => props.activityId, (_newActivityId) => {
    handleShow();
});

onMounted(() => {
    handleShow();
});

</script>
